import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, LinkBox, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Фітнес-центр Apex
			</title>
			<meta name={"description"} content={"Підвищуй свою силу"} />
			<meta property={"og:title"} content={"Фітнес-центр Apex"} />
			<meta property={"og:description"} content={"Підвищуй свою силу"} />
			<meta property={"og:image"} content={"https://zenivolve.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://zenivolve.com/img/4363463.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://zenivolve.com/img/4363463.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://zenivolve.com/img/4363463.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://zenivolve.com/img/4363463.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://zenivolve.com/img/4363463.png"} />
			<meta name={"msapplication-TileImage"} content={"https://zenivolve.com/img/4363463.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0">
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
			>
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Дізнайтеся про наші послуги
				</Text>
				<Text
					font="--lead"
					color="--darkL2"
					text-align="center"
					md-width="100%"
				>
					Ми надаємо широкий спектр послуг для підтримки вашої фітнес-подорожі. У фітнес-центрі Apex ми прагнемо надати вам інструменти, рекомендації та засоби, необхідні для досягнення ваших цілей у сфері здоров'я та фітнесу. Наш найсучасніший тренажерний зал та досвідчена команда допоможуть вам отримати максимум користі від кожного відвідування. Пориньте у світ наших різноманітних послуг і дізнайтеся, як ми можемо допомогти вам покращити свій фітнес-досвід.
				</Text>
			</Box>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://zenivolve.com/img/6.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
					Персоналізовані програми тренувань
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
					У фітнес-центрі Apex ми розуміємо, що кожна людина має унікальні фітнес-цілі та вимоги. Наші персоналізовані програми тренувань розроблені з урахуванням ваших конкретних потреб, незалежно від того, чи прагнете ви наростити м'язи, схуднути або поліпшити загальну фізичну форму. Наші досвідчені тренери працюють разом з вами над створенням індивідуального плану тренувань, який максимально розкриє ваш потенціал і допоможе досягти поставлених цілей ефективно та безпечно.
					<br/><br/>
Початкова оцінка фізичної форми: Наші тренери проводять ретельну оцінку, щоб зрозуміти ваш поточний рівень фізичної підготовки та цілі.<br/>
Індивідуальні плани тренувань: На основі оцінки ми розробляємо персоналізований план тренувань, який відповідає вашим цілям.<br/>
Відстеження прогресу: Регулярні оцінки гарантують, що ви перебуваєте на правильному шляху, і дозволяють вносити корективи у ваш план за потреби.<br/>
Індивідуальні тренування: насолоджуйтеся перевагами персоналізованої уваги та рекомендацій від наших сертифікованих тренерів.

					</Text>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://zenivolve.com/img/7.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="0px 0px 8px 0px">
					Групові заняття з фітнесу
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--grey">
					Групові заняття з фітнесу у фітнес-центрі Apex - це веселий і мотивуючий спосіб підтримувати себе у формі. У нашому різноманітному розкладі занять знайдеться щось для кожного, незалежно від рівня фізичної підготовки та інтересів.
					<br/><br/>
Високоінтенсивні інтервальні тренування (HIIT): спалюйте калорії та підвищуйте витривалість за допомогою наших високоенергетичних класів HIIT.
<br/>
Йога: покращуйте гнучкість, рівновагу та ясність розуму за допомогою різних стилів йоги, що підходять для всіх рівнів.<br/>
Пілатес: Зміцніть м'язи та підвищіть м'язовий тонус на наших динамічних заняттях з пілатесу.<br/>
Зумба: Танцюйте свій шлях до фітнесу з нашими веселими та захоплюючими заняттями зумбою.<br/>
Заняття на велотренажерах: Відчуйте захоплення від їзди на велосипеді в приміщенні та покращіть здоров'я серцево-судинної системи на наших заняттях зі спінінгу.

					</Text>
				</Box>
			</LinkBox>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="100%" />
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="rgba(238, 205, 108, 0.7)"
				md-padding="48px 40px 48px 40px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="left"
					sm-font="--headline3"
				>
					Приєднуйтесь до нас сьогодні!
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="left"
					md-margin="0px 0px 28px 0px"
				>
					Зробіть наступний крок до здорового та сильного себе. Приєднуйтесь до фітнес-центру "Апекс" вже сьогодні і випробуйте комплексні послуги, які ми пропонуємо, щоб допомогти вам досягти ваших фітнес-цілей. Давайте разом зробимо вашу фітнес-подорож більш ефективною!
				</Text>
				<Link
					href="/contact"
					color="--lightD1"
					text-decoration-line="initial"
					padding="7px 24px 8px 24px"
					background="--color-red"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					text-transform="uppercase"
					letter-spacing="1px"
					border-radius="4px"
					target="_blank"
				>
					Контакти
				</Link>
			</Box>
			<Box
				display="flex"
				width="60%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				lg-order="-1"
			>
				<Image
					src="https://zenivolve.com/img/a5.jpg"
					object-fit="cover"
					max-height="600px"
					width="100%"
					object-position="0% 0%"
					sm-max-height="500px"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65ccedc78e2e8e0021782120"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});